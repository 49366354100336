import * as React from 'react';
import * as _ from 'lodash';
import { NavLink } from 'react-router-dom';
import { IndexLink } from 'react-router-dom';
import { Link as ToolLink } from 'react-toolbox/lib/link';
import { Layout } from 'react-toolbox';
import { NavDrawer } from 'react-toolbox';
import { Panel } from 'react-toolbox';
import { AppBar } from 'react-toolbox/lib/app_bar';
import { Navigation } from 'react-toolbox/lib/navigation';
import { IconButton } from 'react-toolbox';
import { FontIcon } from 'react-toolbox/lib/font_icon';
import ProgressBar from 'react-toolbox/lib/progress_bar';
import Sidemenu from '../practitioner_menu';
import { Menu } from 'react-toolbox/lib/menu';
import { MenuItem } from 'react-toolbox/lib/menu';
import { MenuDivider } from 'react-toolbox/lib/menu';
import Button from 'react-toolbox/lib/button';
import { Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import NotFound from '../../../core/components/not_found';
import PractitionerHome from '../practitioner_home';
import Dashboard from '../dashboard';
import PlansSplashPage from '../buy_plans_splash';
import ProfileEdit from '../../../user/components/profile_edit';
import SearchPreference from '../../../user/components/search_preferences';
import UserEmailUpdate from '../../../user/components/user_email_update';
import UserPasswordUpdate from '../../../user/components/user_password_update';
import MetricSettings from '../../../user/components/metric_settings';
import MessageList from '../../../message/components/messages';
import MyNews from '../../../news/components/my_news';
import PracticeCreate from '../../../practice/components/practice_create';
import PractitionerClientInvite from '../../../engagement/components/practitioner_client_invite';
import PractitionerClientEdit from '../../../engagement/components/client_edit';
import PractitionerClientNewEngagement from '../../../engagement/components/practitioner_client_new_engagement';
import PractitionerClientHome from '../../../engagement/components/practitioner_client_home';
import EngagementAssessmentAddEdit from '../../../engagement/components/engagement_assessment_summary';
import EngagementHome from '../../../engagement/components/engagment';
import PractitionerClients from '../../../engagement/components/practitioner_client_list';
import GroupDashboard from '../../../group/components/group_dashboard';
import GroupDashboardNew from '../../../group_new/components/group_dashboard';
import PractitionerGroupEdit from '../../../group/components/group_edit';
import PractitionerGroup from '../../../group/components/group_list';
import ChatList from '../../../conversation/components/chat_list';
import ConversationList from '../../../conversation/components/conversation_list';
import ProgressionPlay from '../../../program/components/progression_play';
import ProgressionTrack from '../../../program/components/progression_track';
import NewsView from '../../../news/components/news_view';
import NewsEdit from '../../../news/components/news_edit';
import NewsList from '../../../news/components/news_list';
import CalendarContainer from '../../../calendar/components/calendar_container';
import CalendarContainerNew from '../../../homefit_scheduler/components/calendar_container';
import EngagementContainer from '../engagement_container';
import BrandingContainer from '../branding_container';
import ProgramContainer from '../../../program/components/program_container';
import AdminContainer from '../../../admin/components/admin_container';
import PartnerContainer from '../../../partner/components/partner_container';
import PaymentContainer from '../../../payment/components/payment_container';
import VideoTutorials from '../../../news/components/video_tutorials';
import Maps3dContainer from '../../../maps3d/components/container';
import SettingsContainer from '../settings_container';
import MatAssessment from '../../../mat/components/mat_configure';
import WorkfitContainer from '../../../dhf/workfit/components/workfit_practitioner_container';
import WorkfitVideos from '../../../dhf/workfit/components/workfit_videos';
import WorkfitVideoEdit from '../../../dhf/workfit/components/workfit_video_edit';
import MoveContainer from '../../../dhf/move/components/move_container.jsx';
import MoveVideoEdit from '../../../dhf/move/components/move_video_edit';
import DHFAssessment from '../../../dhf/assess/components/assessment_list';
import WorkfitReportsContainer from '../workfit_menu_container';
import Courses from '../../../courses/components/courses';
import CoursesContentEditContainer from '../../../courses/components/course_edit_container';
import CoursesContentAddContainer from '../../../courses/components/course_add_container';
import Subscription from '../../../subscription_log/components/subscription';
import Users from '../../../users/components/users';
import UsersEdit from '../../../users/components/users_edit';
import UsersDetail from '../../../users/components/users_detail';
import PromoBanner from '../../../marketing/components/banners';
import PromoBannerEdit from '../../../marketing/components/banner_edit';
import Notifications from '../../../marketing/components/notifications';
import NotificationEdit from '../../../marketing/components/notification_edit';
import Bundles from '../../../marketing/components/bundles';
import BundlesEdit from '../../../marketing/components/bundles_edit';
import HowToVideos from '../../../marketing/components/how-to-videos';
import HowToVideosEdit from '../../../marketing/components/how-to-videos_edit';
import EducationPractice from '../../../education/components/education_practice';
import CannedMessageEditNew from '../../../message_new/components/canned_message_edit';
import CannedMessagesNew from '../../../message_new/components/canned_messages';
import { PARTNER } from '../../../core/constants/constants';
import Onboard from '../../../auth/components/welcome_aboard';
import SwaggerUI from '../swagger_ui';
import { SwaggerHomefitComponent as SwaggerHomefitUI } from '../swagger_ui';
import { SwaggerGIComponent as SwaggerGiAppUI } from '../swagger_ui';
export default function () {
    function repeatInvited_partner1(invited_partner, invited_partnerIndex) {
        return React.createElement('div', { 'className': 'alert-warning text-center p-t-5 p-b-5' }, React.createElement('div', {}, React.createElement('span', {}, invited_partner.partner.name, ' has invited you to subscribe to their content'), React.createElement(Button, {
            'className': 'bg-success m-l-25 small-button',
            'onClick': () => {
                this.acceptPartnerRequest(invited_partner);
            },
            'label': 'Accept',
            'icon': 'done',
            'raised': true
        }), React.createElement(Button, {
            'className': 'bg-danger m-l-25 small-button',
            'onClick': () => {
                this.rejectPartnerRequest(invited_partner);
            },
            'label': 'Reject',
            'icon': 'clear',
            'raised': true
        })));
    }
    return React.createElement('div', { 'className': 'd-flex p-t-20' }, React.createElement(NavDrawer, {
        'className': 'main-drawer',
        'active': this.props.menuopen,
        'pinned': this.props.pinned,
        'onOverlayClick': this.toggleDrawerActive
    }, React.createElement(Sidemenu, {
        'isAuthenticated': true,
        'location': this.props.location,
        'doLogout': this.props.doLogout
    })), React.createElement.apply(this, [
        'div',
        {
            'className': 'content-zone',
            'style': {
                width: this.props.menuopen ? '83%' : '98%',
                position: 'absolute',
                left: this.props.menuopen ? '230px' : '2%',
                flex: 1,
                transition: 'left,0.5s'
            }
        },
        _.map(this.props.selectedRole.invited_partners, repeatInvited_partner1.bind(this)),
        React.createElement(Switch, {}, React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/home`,
            'component': Dashboard
        }), this.state.hasSupportRole ? React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/swagger`,
            'component': SwaggerUI,
            'key': '9841'
        }) : null, this.state.hasSupportRole ? React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/swagger-homefit-app`,
            'component': SwaggerHomefitUI,
            'key': '9970'
        }) : null, this.state.hasSupportRole ? React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/swagger-gi-app`,
            'component': SwaggerGiAppUI,
            'key': '10118'
        }) : null, React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/onboard`,
            'component': Onboard
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/buy_plans`,
            'component': PlansSplashPage
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/profile_edit`,
            'component': ProfileEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/search_preference`,
            'component': SearchPreference
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/update_email`,
            'component': UserEmailUpdate
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/update_password`,
            'component': UserPasswordUpdate
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/metric_settings`,
            'component': MetricSettings
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/messages`,
            'component': MessageList
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/my_news`,
            'component': MyNews
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/practice/create`,
            'component': PracticeCreate
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client/invite/:invite_type`,
            'component': PractitionerClientInvite
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client/details/:client_id`,
            'component': PractitionerClientEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client/dashboard/:client_id/new`,
            'component': PractitionerClientNewEngagement
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client/dashboard/:client_id`,
            'component': PractitionerClientHome
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client/engagement/:engagement_id/assessment/edit/:assessment_id`,
            'component': EngagementAssessmentAddEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client/engagement/:engagement_id/assessment/add`,
            'component': EngagementAssessmentAddEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client/:client_id/engagement/:engagement_id`,
            'component': EngagementHome
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client/engagement/:engagement_id`,
            'component': EngagementHome
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client`,
            'component': PractitionerClients
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/client/select/:selectionType`,
            'component': PractitionerClients
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/maps3d/:client_id`,
            'component': Maps3dContainer
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/mat/:client_id`,
            'component': MatAssessment
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/group/dashboard/:group_id`,
            'component': GroupDashboardNew
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/group/edit/:group_id`,
            'component': PractitionerGroupEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/group/add`,
            'component': PractitionerGroupEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/group`,
            'component': PractitionerGroup
        }), PARTNER.app_type === 'gi' ? React.createElement(Route, {
            'path': `${ this.props.match.url }/scheduler`,
            'component': CalendarContainerNew,
            'key': '13705'
        }) : null, React.createElement(Route, {
            'path': `${ this.props.match.url }/scheduler`,
            'component': CalendarContainer
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/conversation/:conversation_id`,
            'component': ChatList
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/conversation`,
            'component': ConversationList
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/progression_play/:progression_id`,
            'component': ProgressionPlay
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/progression_track/:progression_id/:date`,
            'component': ProgressionTrack
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/progression_track/:progression_id`,
            'component': ProgressionTrack
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/engagement`,
            'component': EngagementContainer
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/pw/:program_type/:mode/:workout_target_type/:workout_target_id`,
            'component': ProgramContainer
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/branding`,
            'component': BrandingContainer
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/news/view/:news_id`,
            'component': NewsView
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/news/edit/:news_id`,
            'component': NewsEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/news/add`,
            'component': NewsEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/news`,
            'component': NewsList
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/admin`,
            'component': AdminContainer
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/partner`,
            'component': PartnerContainer
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/payments`,
            'component': PaymentContainer
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/video_tutorials`,
            'component': VideoTutorials
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/workfit`,
            'component': WorkfitContainer
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/move/add`,
            'component': MoveVideoEdit
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/move/edit/:move_video_id`,
            'component': MoveVideoEdit
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/move`,
            'component': MoveContainer
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/dhfassess/:assess_type/:client_id`,
            'component': DHFAssessment
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/settings`,
            'component': SettingsContainer
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/education/courses`,
            'component': Courses
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/education/:course_type/add`,
            'component': CoursesContentAddContainer
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/education/:course_type/edit/:course_id`,
            'component': CoursesContentEditContainer
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/education`,
            'component': EducationPractice
        }), React.createElement(Route, {
            'path': `${ this.props.match.url }/workfit_reports`,
            'component': WorkfitReportsContainer
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/email_template/edit/:message_type`,
            'component': CannedMessageEditNew
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/email_template`,
            'component': CannedMessagesNew
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/subscriptions`,
            'component': Subscription
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/users`,
            'component': Users
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/users/add`,
            'component': UsersEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/users/edit/:user_id`,
            'component': UsersEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/users/:user_id`,
            'component': UsersDetail
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/banners/:banner_type?`,
            'component': PromoBanner
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/banners/:banner_type/add`,
            'component': PromoBannerEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/banners/:banner_type/edit/:banner_id`,
            'component': PromoBannerEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/notifications`,
            'component': Notifications
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/notification/add`,
            'component': NotificationEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/bundles`,
            'component': Bundles
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/bundle/add`,
            'component': BundlesEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/bundle/edit/:bundle_id`,
            'component': BundlesEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/how-to-videos`,
            'component': HowToVideos
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/how-to-videos/add`,
            'component': HowToVideosEdit
        }), React.createElement(Route, {
            'exact': true,
            'path': `${ this.props.match.url }/marketing/how-to-videos/edit/:video_id`,
            'component': HowToVideosEdit
        }), React.createElement(Route, { 'component': NotFound }))
    ]));
}