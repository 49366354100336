import Axios from '../../../utils/network/axios';
import { API_VERSION, PACKAGE } from './../constants/constants';
import { PARTNER } from '../../../core/constants/constants';
import Utils from '../../../utils/utils';
import {showProgress, hideProgress} from '../../core/actions/progress_creator';
import authActionCreator from  '../actions/auth_creator';
import roleActionCreator from  '../actions/role_creator';
import SignupCreator from '../actions/signup_creator';
import store,{history} from "../../../redux/store";

if (!process.env.BROWSER) {
    var Actions = require("react-native-router-flux").Actions;
    var ActionConst = require("react-native-router-flux").ActionConst;
}
const DEVICE = Utils.getDevice();

const verifyToken = () => {
    return (dispatch) => {
        dispatch(showProgress());
        const url = "/" + API_VERSION + "/" + PACKAGE + "/verify_user";
        return Axios.post(url, {})
        .then((response) => {
            dispatch(hideProgress());
            dispatch(authActionCreator.updateAuthUser(response['user']));
            dispatch(authActionCreator.authSuccess(response));
            dispatch(roleActionCreator.updateAllRoles(response['roles']));  
            dispatch(authActionCreator.storeLMSData({
                ssoData: JSON.parse(response['user']['gi_user_cookie'])
            }));  
            let selectedRole = localStorage.getItem('selectedRole') || 0; 
            if(selectedRole !== "client"){
                selectedRole = parseInt(selectedRole);
            }
            let has_client_redirect = false;  
            let hot_redirect =  sessionStorage.getItem("hot_redirect");
            if(hot_redirect != null){
                let hot_redirect_array = hot_redirect.split("/");
                if(hot_redirect_array.length > 1){
                    if(hot_redirect_array[1] === "client"){
                        has_client_redirect = true;
                        localStorage.removeItem("selectedRole");
                        selectedRole = "client";
                    }else if(hot_redirect_array[1] === "practice"){
                        has_client_redirect = true;
                        localStorage.removeItem("selectedRole");
                        selectedRole = 0;
                        let practice = parseInt(hot_redirect_array[2]);
                        if (!isNaN(practice)) {
                            // let found_role = _.find(response['roles'], ['practice.id', practice]);
                            let found_role = _.find(response['roles'], {'practice.id': practice , 'role.name':hot_redirect_array[1]});
                            if (found_role !== undefined) {
                                selectedRole = found_role.id;
                            }                            
                        }
                    }else  if(hot_redirect_array[1] === "practitioner"){
                        has_client_redirect = true;
                        selectedRole = 0;
                        localStorage.removeItem("selectedRole");
                    } else if (hot_redirect_array[1] === "hf") {
                        has_client_redirect = true;
                        localStorage.removeItem("selectedRole");
                        selectedRole = "client";
                    } else if(hot_redirect_array[1] === "gipro") {
                        has_client_redirect = true;
                        selectedRole = "gipro"                        
                        localStorage.removeItem("selectedRole");                        
                    }
                }
                sessionStorage.removeItem("hot_redirect");
            }  
            if(selectedRole !== "gipro") {
                if(selectedRole !== 0) {
                    let roles = response['roles'];
                    let roleIdDict = _.keyBy(roles, 'id');
                    if(selectedRole === "client"){
                        _.forEach(roles,(item)=>{
                            if(item.role.name === "client"){
                                localStorage.setItem('selectedRole',"client");    
                                dispatch(roleActionCreator.chooseRole(item));
                            }
                        });
                    } else if (_.has(roleIdDict, selectedRole)) {
                        localStorage.setItem('selectedRole',selectedRole);  
                        dispatch(roleActionCreator.chooseRole(roleIdDict[selectedRole]));
                    }else if(response['roles'].length > 0){
                        if(response['roles'][0].role.name === "client"){
                            localStorage.setItem('selectedRole',"client");    
                        }else{
                            localStorage.setItem('selectedRole',response['roles'][0].id);    
                        }
                        dispatch(roleActionCreator.chooseRole(response['roles'][0]));
                        has_client_redirect = false;
                        history.push("/");   
                    }
                } else if (response['roles'].length > 0) {
                    let admin=false;
                    _.forEach(response['roles'],(item)=>{
                        if(item.id === 277){
                            admin =item;
                            return false;
                        }else if(item.role.name === 'practice_admin'){
                            admin =item;
                        }
                    });
                    if(!admin){
                        if( response['roles'][0].role.name === "client"){
                            localStorage.setItem('selectedRole',"client");    
                        }else{
                            localStorage.setItem('selectedRole',response['roles'][0].id);    
                        }
                        dispatch(roleActionCreator.chooseRole(response['roles'][0]));
                    }else {
                        localStorage.setItem('selectedRole',admin.id);
                        dispatch(roleActionCreator.chooseRole(admin));
                    }
                } 
            } 
            //Redirects
            if(selectedRole !== "gipro") {
                let role = localStorage.getItem('selectedRole') || 0; 
                if (role !== 0) {
                    let path_array = window.location.pathname.split("/");                
                    if (role === "client") {
                        if(path_array[1] !== "client" && path_array[1] !== "hf"){
                            history.push("/");
                        } else {
                            if(has_client_redirect){
                                if(window.location.pathname !== hot_redirect){
                                    history.push(hot_redirect);
                                }
                            }    
                        }
                    }else{
                        if(has_client_redirect){ // redirect old practitioner url
                            let hot_redirect_array = hot_redirect.split("/");
                            if(hot_redirect_array[1] ==="practitioner"){
                                let practice_roles = _.compact(_.map(response["roles"],"practice.id"));
                                if (practice_roles.length === 1) {
                                    let role_obj = _.find(response.roles, ["id", parseInt(role)]);
                                    hot_redirect_array[1] = "practice";
                                    hot_redirect_array.splice(2,0,role_obj.practice.id);
                                    hot_redirect = hot_redirect_array.join("/");
                                }else{
                                    history.push("/");
                                    dispatch(hideProgress());    
                                    return response;
                                }
                            }
                        }
                        if(path_array[1] !=="practice" && path_array[1] !=="practitioner"){
                            history.push("/");
                        }else{
                            if(has_client_redirect){
                                if(window.location.pathname !== hot_redirect){
                                    history.push(hot_redirect);
                                }
                            }    
                        }
                    }
                }  
            } else {
                history.push("/gipro/specialities");   
            }
            try {
                window.zE(function () {
                    zE.identify({
                        name: response['user'].name,
                        email: response['user'].email,
                    });
                });
            } catch (err){            
            }

            return response;
        })
        .catch((error) => {    
            dispatch(hideProgress());
            dispatch(authActionCreator.authFailure(error));
            throw error;
        });
    };
};

const login = (email, password) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/login";
        return Axios.post(url, { email: email, password: password })
        .then((response) =>  {
            Utils.setLocalStorageValue('token', response['token']);
            dispatch(authActionCreator.updateAuthUser(response['user']));
            dispatch(authActionCreator.storeLMSData({
                is_lms_active: response['is_lms_active'],
                is_lms_admin: response['is_lms_admin'],
                token: response['token'],
                ssoData: JSON.parse(response['user']['gi_user_cookie'])
            }));
            dispatch(authActionCreator.authSuccess(response));
            dispatch(roleActionCreator.updateAllRoles(response['roles']));
            var selectedRole = parseInt(localStorage.getItem('selectedRole')) || 0;
            if(selectedRole !== "client"){
                selectedRole = parseInt(selectedRole);
            }
            let has_client_redirect = false;  
            let hot_redirect = sessionStorage.getItem("hot_redirect");            
            if(hot_redirect != null){
                let hot_redirect_array = hot_redirect.split("/");
                if(hot_redirect_array.length > 1){
                    if(hot_redirect_array[1] === "client"){
                        has_client_redirect = true;
                        localStorage.removeItem("selectedRole");
                        selectedRole = "client";
                    }else if(hot_redirect_array[1] === "practice"){
                        has_client_redirect = true;
                        localStorage.removeItem("selectedRole");
                        selectedRole = 0;
                        let practice = parseInt(hot_redirect_array[2]);
                        if (!isNaN(practice)) {
                            let found_role = _.find(response['roles'], {'practice.id': practice , 'role.name':hot_redirect_array[1]});
                            if (found_role !== undefined) {
                                selectedRole = found_role.id;
                            }
                            
                        }
                    }else  if(hot_redirect_array[1] === "practitioner"){
                        has_client_redirect = true;
                        selectedRole = 0;
                        localStorage.removeItem("selectedRole");
                    } else if (hot_redirect_array[1] === "hf") {
                        has_client_redirect = true;
                        localStorage.removeItem("selectedRole");
                        selectedRole = "client";
                    } else if(hot_redirect_array[1] === "gipro") {
                        has_client_redirect = true;
                        selectedRole = "gipro"                        
                        localStorage.removeItem("selectedRole");                        
                    }
                }
                sessionStorage.removeItem("hot_redirect");
            }  
            if(selectedRole !== "gipro") {
                if(selectedRole !== 0) {
                    let roles = response['roles'];
                    let roleIdDict = _.keyBy(roles, 'id');
                    if(has_client_redirect && selectedRole === "client"){
                        _.forEach(roles,(item)=>{
                            if(item.role.name === "client"){
                                localStorage.setItem('selectedRole',"client"); 
                                dispatch(roleActionCreator.chooseRole(item));  
                            }
                        });
                    }else if(_.has(roleIdDict, selectedRole )){
                        localStorage.setItem('selectedRole',selectedRole);                     
                        dispatch(roleActionCreator.chooseRole(roleIdDict[selectedRole]));
                    }else if('roles' in response && response['roles'].length > 0){
                        if(response['roles'][0].role.name === "client"){
                            localStorage.setItem('selectedRole',"client");    
                        }else{
                            localStorage.setItem('selectedRole',response['roles'][0].id);    
                        }
                        dispatch(roleActionCreator.chooseRole(response['roles'][0]));
                        has_client_redirect = false;
                        history.push("/");  
                    }
                } else if('roles' in response && response['roles'].length > 0){
                    let admin=false;
                    _.forEach(response['roles'],(item)=>{
                        if(item.id === 277){
                            admin =item;
                            return false;
                        }else if(item.role.name === 'practice_admin'){
                            admin =item;
                        }
                    });
                    if(!admin){

                        if( response['roles'][0].role.name === "client"){
                            
                            localStorage.setItem('selectedRole',"client");    
                        }else{
                            localStorage.setItem('selectedRole',response['roles'][0].id);    
                        }
                        dispatch(roleActionCreator.chooseRole(response['roles'][0]));
                    }else{
                        localStorage.setItem('selectedRole',admin.id);    
                        dispatch(roleActionCreator.chooseRole(admin));
                    }
                    // if(response['roles'][0].role.name === "client"){
                    //     localStorage.setItem('selectedRole',"client");    
                    // }else{
                    //     localStorage.setItem('selectedRole',response['roles'][0].id);    
                    // }
                    // dispatch(roleActionCreator.chooseRole(response['roles'][0]));
                }      
            } 
            //Redirects
            if(selectedRole !== "gipro") {
                let role = localStorage.getItem('selectedRole') || 0; 
                if (role !== 0) {
                    let path_array = null;
                    if (has_client_redirect) {
                        path_array = hot_redirect.split("/");
                    } else {
                        path_array = window.location.pathname.split("/");
                    }
                    if(role === "client"){
                        if (path_array[1] !== "client" && path_array[1] !== "hf") {
                            history.push("/");
                        } else {
                            if (has_client_redirect) {                            
                                if(window.location.pathname !== hot_redirect){
                                    history.push(hot_redirect);
                                }
                            }    
                        }
                    }else{
                        if(has_client_redirect){ // redirect old practitioner url
                            let hot_redirect_array = hot_redirect.split("/");
                            if(hot_redirect_array[1] === "practitioner"){
                                let practice_roles = _.compact(_.map(response["roles"],"practice.id"));
                                if(practice_roles.length === 1){
                                    let role_obj = _.find(response.roles, ["id", parseInt(role)]);
                                    hot_redirect_array[1] = "practice";
                                    hot_redirect_array.splice(2,0,role_obj.practice.id);
                                    hot_redirect = hot_redirect_array.join("/");
                                }else{
                                    history.push("/");
                                    dispatch(hideProgress());    
                                    return response;
                                }
                            }
                        }
                        if(path_array[1] !== "practice" && path_array[1] !== "practitioner"){
                            history.push("/");
                        }else{
                            if(has_client_redirect){
                                if(window.location.pathname !== hot_redirect){
                                    history.push(hot_redirect);
                                }
                            }    
                        }
                    }
                } 
            } else {
                history.push("/gipro/specialities"); 
            }             
            dispatch(hideProgress());   
            return response;      
        })
        .catch((error) => { 
            dispatch(hideProgress());   
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(authActionCreator.authFailure(error));
            throw error;
        });
    };
};

const onboard = (email, password) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/login";
        return Axios.post(url, { email: email, password: password })
            .then((response) =>  {
            Utils.setLocalStorageValue('token', response['token']);
            dispatch(authActionCreator.updateAuthUser(response['user']));
            dispatch(authActionCreator.authSuccess(response));
            dispatch(roleActionCreator.updateAllRoles(response['roles']));
            var selectedRole = parseInt(localStorage.getItem('selectedRole')) || 0;  
            if(selectedRole !== "client"){
                selectedRole = parseInt(selectedRole);
            }
            let has_client_redirect = false;  
            let hot_redirect = sessionStorage.getItem("hot_redirect");            
            if(hot_redirect != null){
                let hot_redirect_array = hot_redirect.split("/");
                if(hot_redirect_array.length > 1){
                    if(hot_redirect_array[1] === "client"){
                        has_client_redirect = true;
                        localStorage.removeItem("selectedRole");
                        selectedRole = "client";
                    }else if(hot_redirect_array[1] === "practice"){
                        has_client_redirect = true;
                        localStorage.removeItem("selectedRole");
                        selectedRole = 0;
                        let practice = parseInt(hot_redirect_array[2]);
                        if (!isNaN(practice)) {
                            let found_role = _.find(response['roles'], ['practice.id', practice]);
                            if (found_role !== undefined) {
                                selectedRole = found_role.id;
                            }
                            
                        }
                    }else  if(hot_redirect_array[1] === "practitioner"){
                        has_client_redirect = true;
                        selectedRole = 0;
                        localStorage.removeItem("selectedRole");
                    } else if (hot_redirect_array[1] === "hf") {
                        has_client_redirect = true;
                        localStorage.removeItem("selectedRole");
                        selectedRole = "client";
                    } else if(hot_redirect_array[1] === "gipro") {
                        has_client_redirect = true;
                        selectedRole = "gipro"                        
                        localStorage.removeItem("selectedRole");                        
                    }
                }
                sessionStorage.removeItem("hot_redirect");
            }  
            if(selectedRole !== "gipro") {
                if(selectedRole !== 0) {
                    let roles = response['roles'];
                    let roleIdDict = _.keyBy(roles, 'id');
                    if(has_client_redirect && selectedRole === "client"){
                        _.forEach(roles,(item)=>{
                            if(item.role.name === "client"){
                                localStorage.setItem('selectedRole',"client"); 
                                dispatch(roleActionCreator.chooseRole(item));  
                            }
                        });
                    }else if(_.has(roleIdDict, selectedRole )){
                        localStorage.setItem('selectedRole',selectedRole);                     
                        dispatch(roleActionCreator.chooseRole(roleIdDict[selectedRole]));
                    }else if(response['roles'].length > 0){
                        if(response['roles'][0].role.name === "client"){
                            localStorage.setItem('selectedRole',"client");    
                        }else{
                            localStorage.setItem('selectedRole',response['roles'][0].id);    
                        }
                        dispatch(roleActionCreator.chooseRole(response['roles'][0]));
                        has_client_redirect = false;
                        history.push("/");  
                    }
                } else if(response['roles'].length > 0){
                    if(response['roles'][0].role.name === "client"){
                        localStorage.setItem('selectedRole',"client");    
                    }else{
                        localStorage.setItem('selectedRole',response['roles'][0].id);    
                    }
                    dispatch(roleActionCreator.chooseRole(response['roles'][0]));
                }      
            } 
            //Redirects
            if(selectedRole !== "gipro") {
                let role = localStorage.getItem('selectedRole') || 0; 
                if (role !== 0) {
                    let path_array = null;
                    if (has_client_redirect) {
                        path_array = hot_redirect.split("/");
                    } else {
                        path_array = window.location.pathname.split("/");
                    }
                    if(role === "client"){
                        if (path_array[1] !== "client" && path_array[1] !== "hf") {
                            history.push("/");
                        } else {
                            if (has_client_redirect) {                            
                                if(window.location.pathname !== hot_redirect){
                                    history.push(hot_redirect);
                                }
                            }    
                        }
                    }else{
                        if(has_client_redirect){ // redirect old practitioner url
                            let hot_redirect_array = hot_redirect.split("/");
                            if(hot_redirect_array[1] === "practitioner"){
                                let practice_roles = _.compact(_.map(response["roles"],"practice.id"));
                                if(practice_roles.length === 1){
                                    let role_obj = _.find(response.roles, ["id", parseInt(role)]);
                                    hot_redirect_array[1] = "practice";
                                    hot_redirect_array.splice(2,0,role_obj.practice.id);
                                    hot_redirect = hot_redirect_array.join("/");
                                }else{
                                    history.push("/");
                                    dispatch(hideProgress());    
                                    return response;
                                }
                            }
                        }
                        if(path_array[1] !== "practice" && path_array[1] !== "practitioner"){
                            history.push("/");
                        }else{
                            if(has_client_redirect){
                                if(window.location.pathname !== hot_redirect){
                                    history.push(hot_redirect);
                                }
                            }    
                        }
                    }
                } 
            } else {
                history.push("/gipro/specialities"); 
            }             
            dispatch(hideProgress());   
            return response;      
        })
        .catch((error) => { 
            dispatch(hideProgress());   
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(authActionCreator.authFailure(error));
            throw error;
        });
    };
};


const facebookLogin = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/login_facebook";
        return Axios.post(url, params)
            .then((response) =>  {
            Utils.setLocalStorageValue('token', response['token']);
            dispatch(authActionCreator.updateAuthUser(response['user']));
            dispatch(authActionCreator.authSuccess(response));
            dispatch(roleActionCreator.updateAllRoles(response['roles']));
            var selectedRole = parseInt(localStorage.getItem('selectedRole')) || 0;            
            if(selectedRole !== 0) {
                let roles = response['roles'];
                let roleIdDict = _.keyBy(roles, 'id');
                if(_.has(roleIdDict, selectedRole )){
                    dispatch(roleActionCreator.chooseRole(roleIdDict[selectedRole]));
                }
            } else if(response['roles'].length > 0){
                if(response['roles'][0].role.name === "client"){
                    localStorage.setItem('selectedRole',"client");    
                }else{
                    localStorage.setItem('selectedRole',response['roles'][0].id);    
                }
                dispatch(roleActionCreator.chooseRole(response['roles'][0]));
            }            
            dispatch(hideProgress());          
        })
        .catch((error) => { 
            dispatch(hideProgress());   
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            dispatch(authActionCreator.authFailure(error));
            throw error;
        });
    };
};


const timezone_list = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/timezone_list";
        return Axios.post(url, params)
            .then((response) => {
                dispatch(hideProgress());   
                dispatch(authActionCreator.timezoneListFill(response.timezones));
           })
        .catch((error) => { 
            dispatch(hideProgress());   
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const onboardSearchParams = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/user/onboard_search_param";
        return Axios.post(url, params)
            .then((response) => {
                dispatch(authActionCreator.getOnboardSearchData(
                    response.supporting_data,
                ));
                dispatch(hideProgress());
                return response
            })
            .catch((error) => { 
                dispatch(hideProgress());   
                if (error.response && error.response.status && error.response.status === 400) {
                    Utils.showErrorAlert(error.response.data.reason);
                }
                throw error;
            })
        };
    };
const onboardUser = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/user/user_onboard";
        return Axios.post(url, params)
            .then((response) => {
                dispatch(authActionCreator.getOnboardData(
                    response.user,
                ));
                dispatch(hideProgress());
                return response
            })
            .catch((error) => { 
                dispatch(hideProgress());   
                if (error.response && error.response.status && error.response.status === 400) {
                    Utils.showErrorAlert(error.response.data.reason);
                }
                throw error;
            })
        };
    };
const ssoLoginLMS = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "https://uat.api.lms.grayinstitute.com/api/ssoLogin";
        return Axios.post(url, params)
            .then((response) => {
                // dispatch(authActionCreator.getOnboardData(
                //     response.user,
                // ));
                dispatch(hideProgress());
                return response
            })
            .catch((error) => { 
                dispatch(hideProgress());   
                if (error.response && error.response.status && error.response.status === 400) {
                    Utils.showErrorAlert(error.response.data.reason);
                }
                throw error;
            })
        };
    };

const signup = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/signup";
        return Axios.post(url, params)
            .then((response) => {
                if (response["token"] !== null && response["token"] !== undefined) {
                    Utils.setLocalStorageValue('token', response['token']);
                    dispatch(authActionCreator.updateAuthUser(response['user']));
                    dispatch(authActionCreator.authSuccess(response));
                    dispatch(roleActionCreator.updateAllRoles(response['roles']));
                    var selectedRole = parseInt(localStorage.getItem('selectedRole')) || 0;  
                    if(selectedRole !== "client"){
                        selectedRole = parseInt(selectedRole);
                    }
                    let has_client_redirect = false;  
                    let hot_redirect = sessionStorage.getItem("hot_redirect");
                    if(hot_redirect != null){
                        let hot_redirect_array = hot_redirect.split("/");
                        if(hot_redirect_array.length > 1){
                            if(hot_redirect_array[1] === "client"){
                                has_client_redirect = true;
                                localStorage.removeItem("selectedRole");
                                selectedRole = "client";
                            }else if(hot_redirect_array[1] === "practice"){
                                has_client_redirect = true;
                                localStorage.removeItem("selectedRole");
                                selectedRole = 0;
                                let practice = parseInt(hot_redirect_array[2]);
                                if (!isNaN(practice)) {
                                    let found_role = _.find(response['roles'], ['practice.id', practice]);
                                    if (found_role !== undefined) {
                                        selectedRole = found_role.id;
                                    }
                                    
                                }
                            }else  if(hot_redirect_array[1] === "practitioner"){
                                has_client_redirect = true;
                                selectedRole = 0;
                                localStorage.removeItem("selectedRole");
                            } else if (hot_redirect_array[1] === "hf") {
                                has_client_redirect = true;
                                localStorage.removeItem("selectedRole");
                                selectedRole = "client";
                            }
                        }
                        sessionStorage.removeItem("hot_redirect");
                    }                      
                    if(selectedRole !== 0) {
                        let roles = response['roles'];
                        let roleIdDict = _.keyBy(roles, 'id');
                        if(has_client_redirect && selectedRole === "client"){
                            _.forEach(roles,(item)=>{
                                if(item.role.name === "client"){
                                    localStorage.setItem('selectedRole',"client"); 
                                    dispatch(roleActionCreator.chooseRole(item));  
                                }
                            });
                        }else if(_.has(roleIdDict, selectedRole )){
                            localStorage.setItem('selectedRole',selectedRole);                     
                            dispatch(roleActionCreator.chooseRole(roleIdDict[selectedRole]));
                        }else if(response['roles'].length > 0){
                            if(response['roles'][0].role.name === "client"){
                                localStorage.setItem('selectedRole',"client");    
                            }else{
                                localStorage.setItem('selectedRole',response['roles'][0].id);    
                            }
                            dispatch(roleActionCreator.chooseRole(response['roles'][0]));
                            has_client_redirect = false;
                            history.push("/");  
                        }
                    } else if(response['roles'].length > 0){
                        if(response['roles'][0].role.name === "client"){
                            localStorage.setItem('selectedRole',"client");    
                        }else{
                            localStorage.setItem('selectedRole',response['roles'][0].id);    
                        }
                        dispatch(roleActionCreator.chooseRole(response['roles'][0]));
                    }  
                    //Redirects
                    let role = localStorage.getItem('selectedRole') || 0; 
                    if (role !== 0) {
                        let path_array = null;
                        if (has_client_redirect) {
                            path_array = hot_redirect.split("/");
                        } else {
                            path_array = window.location.pathname.split("/");
                        }
                        if(role === "client"){
                            if (path_array[1] !== "client" && path_array[1] !== "hf") {
                                history.push("/");
                            } else {
                                if (has_client_redirect) {                            
                                    if(window.location.pathname !== hot_redirect){
                                        history.push(hot_redirect);
                                    }
                                }    
                            }
                        }else{
                            if(has_client_redirect){ // redirect old practitioner url
                                let hot_redirect_array = hot_redirect.split("/");
                                if(hot_redirect_array[1] === "practitioner"){
                                    let practice_roles = _.compact(_.map(response["roles"],"practice.id"));
                                    if(practice_roles.length === 1){
                                        let role_obj = _.find(response.roles, ["id", parseInt(role)]);
                                        hot_redirect_array[1] = "practice";
                                        hot_redirect_array.splice(2,0,role_obj.practice.id);
                                        hot_redirect = hot_redirect_array.join("/");
                                    }else{
                                        history.push("/onboard");
                                        // history.push("/");
                                        dispatch(hideProgress());    
                                        return response;
                                    }
                                }
                            }
                            if(path_array[1] !== "practice" && path_array[1] !== "practitioner"){
                                history.push("/auth/onboard");
                            }else{
                                if(has_client_redirect){
                                    history.push("/onboard");
                                    // if(window.location.pathname !== hot_redirect){
                                    //     history.push(hot_redirect);
                                    // }
                                }    
                            }
                        }
                    }                       
                    if(PARTNER.subdomain === 'dhf' && response['roles'][0]["practice"] !== undefined) {
                        dispatch(hideProgress()); 
                        history.push("/practice/" + response['roles'][0]["practice"]["id"] + "/payments/my_plans");
                    } else {
                        dispatch(hideProgress()); 
                        return response;      
                    }
                } else {
                    dispatch(hideProgress());
                    dispatch(SignupCreator.authSignupComplete());
                    return response;
                }
            })
        .catch((error) => { 
            dispatch(hideProgress());   
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const checkUserActivationGuid = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/user_check_valid_guid";
        return Axios.post(url, params)
            .then((response) => {                                 
            dispatch(hideProgress());
            return response;    
        })
        .catch((error) => { 
            dispatch(hideProgress());            
            throw error;
        });
    };
};

const activate = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/user_activate";
        return Axios.post(url, params)
            .then((response) => {      
            Utils.setLocalStorageValue('token', response['token']);
            dispatch(authActionCreator.updateAuthUser(response['user']));   
            dispatch(roleActionCreator.updateAllRoles(response['roles']));    
            history.push('/');      
            dispatch(authActionCreator.authSuccess(response));
            var selectedRole = parseInt(localStorage.getItem('selectedRole')) || 0;            
            if(selectedRole !== 0) {
                let roles = response['roles'];
                let roleIdDict = _.keyBy(roles, 'id');
                if(_.has(roleIdDict, selectedRole )){
                    dispatch(roleActionCreator.chooseRole(roleIdDict[selectedRole]));
                }
            } else if(response['roles'].length > 0){
                localStorage.setItem('selectedRole',response['roles'][0].id);
                dispatch(roleActionCreator.chooseRole(response['roles'][0]));
            }      
            dispatch(hideProgress());       
            Utils.showSuccessAlert("Welcome to GoMotive. Your user account has been activated.");            
            
            return response;    
        })
        .catch((error) => { 
            dispatch(hideProgress());   
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const giApiLogin = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/gi_api_login";
        return Axios.post(url, params)
            .then((response) => {      
            Utils.setLocalStorageValue('token', response['token']);
            dispatch(authActionCreator.updateAuthUser(response['user']));   
            dispatch(roleActionCreator.updateAllRoles(response['roles']));    
            history.push('/');      
            dispatch(authActionCreator.authSuccess(response));
            var selectedRole = parseInt(localStorage.getItem('selectedRole')) || 0;            
            if(selectedRole !== 0) {
                let roles = response['roles'];
                let roleIdDict = _.keyBy(roles, 'id');
                if(_.has(roleIdDict, selectedRole )){
                    dispatch(roleActionCreator.chooseRole(roleIdDict[selectedRole]));
                }
            } else if(response['roles'].length > 0){
                localStorage.setItem('selectedRole',response['roles'][0].id);
                dispatch(roleActionCreator.chooseRole(response['roles'][0]));
            }      
            dispatch(hideProgress());
            return response;    
        })
        .catch((error) => { 
            dispatch(hideProgress());   
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};

const passwordReset = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/password_reset";
        return Axios.post(url, params)
            .then((response) => {
                dispatch(hideProgress());   
                Utils.showSuccessAlert("An email has been sent to your address with a link to reset your password");
                return response;
           })
        .catch((error) => { 
            dispatch(hideProgress());   
            if (error.response && error.response.status && error.response.status === 400) {
                Utils.showErrorAlert(error.response.data.reason);
            }
            throw error;
        });
    };
};


const generateWorkfitPin = (params) => {
    return (dispatch) => {
        dispatch(showProgress());        
        const url = "/" + API_VERSION + "/" + PACKAGE + "/workfit_generate_pin";
        return Axios.post(url, params)
            .then((response) => {                                 
                dispatch(hideProgress());
                dispatch(authActionCreator.generateWorkfitPin(response['pin']));
            return response;    
        })
        .catch((error) => { 
            dispatch(hideProgress());            
            throw error;
        });
    };
};


export {
    login,
    onboard,
    verifyToken,
    timezone_list,
    signup,
    activate,
    passwordReset,
    facebookLogin,
    giApiLogin,
    checkUserActivationGuid,
    generateWorkfitPin,
    onboardSearchParams,
    onboardUser,
    ssoLoginLMS
};

