import React from 'react'
import SwaggerUI from "swagger-ui-react";
import "../../../styles/swagger-ui.css";

export default function SwaggerComponent() {
  return (
    <div id='swagger'>
        <SwaggerUI url='/site_media/static/files/swagger.json' />
    </div>
  )
}
export function SwaggerHomefitComponent() {
  return (
    <div id='swagger-mobile'>
        <SwaggerUI url='/site_media/static/files/homefit_mobile_api_swagger.json' />
    </div>
  )
}
export function SwaggerGIComponent() {
  return (
    <div id='swagger-mobile'>
        <SwaggerUI url='/site_media/static/files/gi_app_api_swagger.json' />
    </div>
  )
}